import { useContext, useState } from "react";
import stateProvider from "../context/stateProvider";

export const useInput = () => {
    const [suggestions, setSuggestions] = useState([]);
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);
    // @ts-ignore
    const { setLocation, location, /* setDropdownLocationError */ } =
        useContext(stateProvider);

    const handleChange = async (e: any, cb: () => void) => {
        if (location === undefined) {
            setLocation('');
        }
        setLocation(e.target.value);

        try {
            const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.target.value}.json?types=country,address,place&access_token=pk.eyJ1IjoiaGltZWwxMjYiLCJhIjoiY2wxZ2FoeHM4MDd2OTNyb3JlcHZub3R4biJ9.iXUC5niBfA83FT2MYlWvpg&autocomplete=true&country=US`;

            const res = await fetch(endpoint);
            const data = await res.json();

            if (data.features.length) {
                setSuggestions(data.features);
                setShow(true);
            }
/*         setDropdownLocationError(false);
 */        cb();
        } catch (error) {
/*         setDropdownLocationError(true);
 */        console.log('Error fetching data, ', error);
        }
    };

    return {
        value: location,
        setLocation,
        onChange: handleChange,
        suggestions,
        setSuggestions,
        error,
        show,
        setError,
        setShow,
    };
};