import { Grid, GridItem, Flex, Text } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Button, Spinner, Box, Image, Input } from '@chakra-ui/react';
import { arrayUnion, doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { BiUserCircle } from 'react-icons/bi';
import { BsTelephone } from 'react-icons/bs';
import { MdAlternateEmail } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { db } from '../../lib/firebase';
import CustomInput from '../CustomInput';
import AnnualReport from './AnnualReport';
import MonthlyData from './MonthlyData';
import { v4 as uuidv4 } from 'uuid';
import { useForm, Controller } from "react-hook-form";

const Result = () => {
  let defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    "User ID": uuidv4()
  };
  const { control, formState: { errors }, handleSubmit, reset, watch, setValue, } = useForm({ defaultValues });
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true)
  const [result, setResult] = useState<any>({});
  const [cookies, setCookie, removeCookie] = useCookies(['hasSubmitForm']);
  const [cookiesFormData, setCookiesFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    "User ID": uuidv4()
  }); const [isLargerThan896] = useMediaQuery('(min-width: 896px)');
  let watchr = watch(['phone'])

  const [isCookiesLoading, setIsCookiesLoading] = useState(false)

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan1600] = useMediaQuery('(min-width: 1600px)');
  const [isLargerThan1100] = useMediaQuery('(min-width: 1100px)');

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    (async () => {
      const ref = doc(db, `results/${id}`);
      const snapshot = await getDoc(ref);
      setResult(snapshot.data());
      setIsLoading(false);
    })();

    return () => {};
  }, []); 


  const handleSetCookie = () => {
    let watcher = watch(['phone', 'firstName', 'lastName', 'email', 'User ID']);

    const info = JSON.stringify({
      phone: watcher[0],
      firstName: watcher[1],
      lastName: watcher[2],
      email: watcher[3],
      'User ID': watcher[4]
    })
    setCookie('hasSubmitForm', info)
  }


  const handleChange = (e: any) => {
    const { value, name } = e.target;

    setCookiesFormData({
      ...cookiesFormData,
      [name]: value,
    });
  }


  const handleClickCookie = async (data: any) => {
    if (('' + data.phone).replace(/\D/g, '').length !== 10) {
      setIsPhoneNumberValid(false);
      return;
    }
    let watcher = watch(['phone', 'firstName', 'lastName', 'email', 'User ID']);

    setIsCookiesLoading(true)

    const db = getFirestore();

    // @ts-ignore
    const ref = doc(db, 'results', id);
    getSynced({
      phone: watcher[0],
      firstName: watcher[1],
      lastName: watcher[2],
      email: watcher[3],
      'User ID': watcher[4]
    })

    await updateDoc(ref, {
      referredby: arrayUnion({
        phone: watcher[0],
        firstName: watcher[1],
        lastName: watcher[2],
        email: watcher[3],
        'User ID': watcher[4]
      })
    })
    handleSetCookie();

    setIsCookiesLoading(false)
  }
  let TEXT_COLOR = '#242E43'
  const txtStyles = {
    lineHeight: '1.1',
    letterSpacing: '-2px',
  };

  const getSynced = (ckD?: any, existingCookieUser = false) => {
    const functions = getFunctions()
    const syncViewedBy = httpsCallable(functions, 'syncViewedBy');
    let watcher = watch(['phone', 'firstName', 'lastName', 'email', 'User ID']);

    syncViewedBy({
      user: ckD ? ckD : {
        phone: watcher[0],
        firstName: watcher[1],
        lastName: watcher[2],
        email: watcher[3],
        'User ID': watcher[4]
      },
      property: {
        id: id
      },
      isGate: ckD ? true : false,
      existingCookieUser: existingCookieUser,
      "User ID": ckD && ckD['User ID']
    })
  }
  useEffect(() => {
    if (cookies.hasSubmitForm) {

      setCookiesFormData(cookies.hasSubmitForm);
      getSynced(cookies.hasSubmitForm, true);
      return
    }



  }, [cookies]);

  const getFormErrorMessage = (name: any) => {
    /* @ts-ignore */
    return errors[name] && <Text color={'#ff6565'} fontFamily={'GT Eesti Text Medium'} my={2} >{errors[name].message}</Text>
  };
  let formatPhoneNumber = (str: string) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
  };



  return (
    <Box height={'100%'} >
      {
        cookies.hasSubmitForm ? (
          <>
            {
        isLoading ? (
          <Flex mt={'200px'} flexDir={'column'} align={'center'} justify={'center'}>
            <Spinner color='#25ced0' size='xl' />
            <Text mt={4} fontFamily="GT Eesti Text Medium">Please Wait...</Text>
          </Flex>
        ) : (
            <Grid
              templateColumns={isLargerThan1100 ? '1fr 1fr' : ''}
              templateRows={!isLargerThan1100 ? '1fr 1fr' : ''}
              bg={isLargerThan768 ? '#F5F5F5' : ''}
              height={'100%'}
              margin={'auto'}
            >
              <GridItem
                bg='#fff'
                display={'flex'}
                alignItems={'center'}
                borderBottomLeftRadius={isLargerThan1200 ? '20px' : '0px'}
                borderTopLeftRadius={isLargerThan1200 ? '20px' : '0px'}

              >
                <AnnualReport result={result} isLoading={isLoading} />
              </GridItem>
              <GridItem
                pr={!isLargerThan1100 ? '0' : !isLargerThan1600 ? '3vw' : ''}
                bg='white'
                borderBottomRightRadius={isLargerThan1200 ? '20px' : '0px'}
                borderTopRightRadius={isLargerThan1200 ? '20px' : '0px'}

              >
                <MonthlyData result={result} />

              </GridItem>
            </Grid>
        )
      }
          </>
        ) : (
          <Flex alignItems={'center'} justifyContent={'center'} height={'100%'} >
            <Flex width={'60%'} justify={'center'} direction='column'>

              <Text sx={{ ...txtStyles, fontFamily: 'GT Eesti Text Bold', color: TEXT_COLOR, mt: !isLargerThan768 && '40px' }} textAlign={'center'} fontSize={['35px', '46px']}>                View Annual Revenue Estimate

              </Text>
              <Box >
                <Text
                  mt={'20px'}
                  textAlign={'center'}
                  fontSize={'24px'}
                  letterSpacing={'.7px'}
                  lineHeight='1.1'
                  fontFamily={'GT Eesti Text Medium'}
                >
                  {result.location}
                </Text>
                <Text mb={5} textAlign={'center'} fontFamily={'GT Eesti Text Light'}>
                  {result.bedrooms} bedrooms · {result.bathrooms} baths
                </Text>
              </Box>

                <form onSubmit={handleSubmit(handleClickCookie)}>
                <Flex
                  direction={isLargerThan768 ? 'row' : 'column'}
                  gap={!isLargerThan768 ? '0rem' : '.6rem'}
                >
                    <Box flex={1}>
                      <Controller name="firstName" control={control}
                        rules={{ required: 'First name is required.', }}
                        render={({ field, fieldState }) => (
                          <CustomInput
                            handleChange={handleChange}
                            icon={<BiUserCircle color='rgb(83, 195, 208)' />}
                            // @ts-ignore
                            placeholder='First Name'
                            // @ts-ignore
                            name='firstName'
                            id={field.name} {...field}
                          />
                        )} />
                      {getFormErrorMessage('firstName')}

                    </Box>

                    <Box flex={1} sx={{
                    mt: !isLargerThan768 ? '20px' : '0px',
                    w: '100%'
                  }}>
                      <Controller name="lastName" control={control}
                        rules={{ required: 'Last name is required.', }}
                        render={({ field, fieldState }) => (
                          <CustomInput

                            handleChange={handleChange}
                            icon={<BiUserCircle color='rgb(83, 195, 208)' />}
                            // @ts-ignore
                            placeholder='Last Name'
                            // @ts-ignore

                            name='lastName'
                            id={field.name} {...field}
                          />
                        )} />
                      {getFormErrorMessage('lastName')}

                  </Box>
                </Flex>
                  <Box my={3}>

                    <Controller name="email" control={control}
                      rules={{
                        required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }
                      }}
                      render={({ field, fieldState }) => (
                        <CustomInput

                          handleChange={handleChange}
                          icon={<BiUserCircle color='rgb(83, 195, 208)' />}
                          // @ts-ignore
                          placeholder='Email'
                        // @ts-ignore

                          name='email'
                          id={field.name} {...field}
                        />
                      )} />
                    {getFormErrorMessage('email')}
                  </Box>


                  <Flex sx={{
                    fontFamily: 'GTMedium',
                    borderRadius: '24px',
                    border: '2px solid #E2E8F0',
                    height: '3.125rem',
                    color: 'rgb(80,80,81)',
                    transition: 'all 250ms ease 0s',
                    _placeholder: {
                      color: 'rgb(80,80,81)',
                    },
                    _hover: { border: '2px solid rgb(163, 223, 230)' },
                    _focus: {},
                    _active: {},
                  }} alignItems={'center'}>

                    <Box p={3} fontFamily={'GT Eesti Text Medium'} >
                      <Flex >
                        <Image borderRadius={'100%'} w={'25px'} height={'25px'} src='https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/800px-Flag_of_the_United_States.svg.png?20151118161041' />
                        <Text ml={2}>+1</Text>
                      </Flex>
                    </Box>
                    <Input fontFamily={'GT Eesti Text Medium'} onChange={(e) => {


                      setValue('phone', e.target.value)
                      setIsPhoneNumberValid(true)
                      {/* @ts-ignore */ }

                    }} type={'text'} name="phone" value={formatPhoneNumber(watchr[0])} outline={'none'} border={'none'} _focus={{
                      border: 'none',
                      outline: 'none'
                    }}

                    />


                  </Flex>
                  {
                    !isPhoneNumberValid && !isCookiesLoading ? (

                      <Text color={'#ff6565'} fontFamily={'GT Eesti Text Medium'} my={2} >Please input a valid number</Text>
                    ) : ''
                  }

                  <Button type='submit' w={'100%'} isLoading={isCookiesLoading} mt={4} fontWeight={'400'} bg={'#25ced0'} color={'white'} onClick={handleClickCookie} >
                View result
              </Button>
                </form>


            </Flex>
          </Flex>
        )
      }
    </Box>
  );
};

export default Result;
