// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDG8bAkCAjGlZP-mUAr63pmLtIaXpo-rqc",
    authDomain: "cohostin-revenuebnb.firebaseapp.com",
    projectId: "cohostin-revenuebnb",
    storageBucket: "cohostin-revenuebnb.appspot.com",
    messagingSenderId: "270682024888",
    appId: "1:270682024888:web:1e70da262c2ab7997e3d4d",
    measurementId: "G-J2PQ9RL4KD"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app)
const functions = getFunctions(app);
