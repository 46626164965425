import { Flex, useMediaQuery } from '@chakra-ui/react';
import Step from './Step';

const Logo = () => {
  const [isLargerThan896] = useMediaQuery('(min-width: 896px)');

  return (
    <Flex
      justify={'center'}
      align='center'
      direction={'column'}
      p={isLargerThan896 ? '1.5rem' : ''}
    >
      <Step />
    </Flex>
  );
};

export default Logo;
