import { getIncomePrediction } from "./rapidAPI";
import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from "./firebase";
import { getFunctions, httpsCallable } from "firebase/functions";


export const generateResult = async (counts: any, coordinates: any, user: any, location: any, amenities: any) => {


const data = await getIncomePrediction(counts.bedrooms, counts.bathrooms, coordinates);
const ref = doc(collection(db, "results"))
if(data.message.listings && data.message.example_listings) {
  delete data.message.listings;
  delete data.message.example_listings;

}

await setDoc(ref, {
   ...data.message,
   amenities,
   id: ref.id,
   location,
   ...counts,
  });

  const functions = getFunctions();
  const syncAirtable = httpsCallable(functions, 'syncAirtable');
  if(!('error_reason' in data.message))  {

    syncAirtable({
      user: {
        ...user,
        id: user['User ID'],

      },
      property: {
        address: location,
        professionalOccupancy: data.message.percentile75.occupancy_rate,
        professionalPerYear: data.message.percentile75.revenue,
        isError: String(false),
        searchUrl: window.location.origin + `/results/${ref.id}`
      }
    })
  } else {
    syncAirtable({
      user: {
        ...user,
        "User ID": user['User ID']

      },
      property: {
        address: location,
        professionalOccupancy: '',
        professionalPerYear: '',
        searchUrl: window.location.origin + `/results/${ref.id}`,
        isError: String(true),
      }
    })
  }


  if(data.message.error_reason) {
    return {
      error: true,
      message: 'Not enough result for this location'
    }
  }


    return ref.id
}