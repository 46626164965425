import { Box, Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import { FC, ReactNode, } from 'react';
import Address from '../PropertyDetails/Address';
import PersonalInfo from '../PropertyDetails/PersonalInfo';
import PropertyStats from '../PropertyDetails/PropertyStats';
import {
  default as Result,
} from '../PropertyDetails/Result';

import Header from '../Header';

const getStepComponent = (step: number) => {
  switch (step) {
    case 1:
      return <Address />; 
    case 2:
      return <PersonalInfo />;
    case 3:
      return <PropertyStats />;
    case 4:
      return <Result />;
  }
};
interface BaseLayoutProps {
  children?: ReactNode;
}

const Layout: FC<BaseLayoutProps> = ({ children }) => {
  const [isLargerThan896] = useMediaQuery('(min-width: 896px)');
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');

  return (
    <Box>

      <Header />
            <Grid
        bg={'#F5F5F5'}
        height={'calc(100vh - 85px)'}
        templateRows={isLargerThan896 ? ' minmax(0, 1fr)' : ''}
        p={isLargerThan1200 ? '40px' : '0px'}
        overflowY={'scroll'}
        overflowX={'hidden'}
            >

              <GridItem

                h='100%'
          bg={'white'}
          borderRadius={isLargerThan1200 ? '24px' : '0px'}
          boxShadow={`0px 0px 25px 0px rgba(0, 0, 0, 0.05)`}
              >
          {children}
        </GridItem>


      </Grid>
    </Box>
  );
};

export default Layout;
