import { Box, ChakraProvider } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import { StateContext } from './context/stateProvider';
import { Homepage } from './pages';
import {
  default as PropertyResult,
} from './components/PropertyDetails/Result';
import { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';

const App = () => {


  return (
    <CookiesProvider>
    <ChakraProvider>
      <StateContext>
        <Layout>
          <Routes>
            <Route
              path='/'
              element={<Homepage />}
            />
            <Route path='/results/:id' element={<PropertyResult />} />
          </Routes>

          <Box id="schedule-call" />
        </Layout>
      </StateContext>
    </ChakraProvider>
    </CookiesProvider>
  );
};

export default App;
