import { Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import { useContext } from 'react'
import StepLayoutButton from '../components/Layout/StepLayoutButton';
import Logo from '../components/Logo';
import Address from '../components/PropertyDetails/Address';
import PersonalInfo from '../components/PropertyDetails/PersonalInfo';
import PropertyStats from '../components/PropertyDetails/PropertyStats';
import stateProvider from '../context/stateProvider';


const getStepComponent = (step: number) => {
    switch (step) {
        case 1:
            return <Address />;
        case 2:
            return <PersonalInfo />;
        case 3:
            return <PropertyStats />;

    }
};
export const Homepage = () => {
    const { step } = useContext(stateProvider);
    const [isLargerThan896] = useMediaQuery('(min-width: 896px)');

    return (

        <Grid
            templateColumns={isLargerThan896 ? '1fr 1fr' : ''}
            templateRows={!isLargerThan896 ? 'max-content 1fr' : ''}
            height={'100%'}
            w={isLargerThan896 ? '1000px' : '100%'}
            gap='1rem'
            margin={'auto'}
            px={'3vw'}
        >


            <GridItem alignSelf={'center'}>
                {getStepComponent(step)}
                <StepLayoutButton />
            </GridItem>

            <GridItem
                rowStart={!isLargerThan896 ? 1 : 'auto'}
                bg='white'
                alignSelf={'center'}
            >
                <Logo />
            </GridItem>

        </Grid>
    )
}
