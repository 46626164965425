import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { useContext } from 'react';
import { AiFillDollarCircle } from 'react-icons/ai';
import { FaHome, FaMapMarkerAlt, FaUserCircle } from 'react-icons/fa';
import BlurredImg from '../assets/images/blurredImg.png';
import stateProvider from '../context/stateProvider';

const Step = () => {
  const [isLargerThan896] = useMediaQuery('(min-width: 896px)');
  // @ts-ignore
  const { location, step, profileInfo } = useContext(stateProvider);

  const boxStyles = {
    background: '#F5F7F9',
    width: '60px',
    height: '60px',
    borderRadius: '50px',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const borderStyles = {
    width: '100%',
    height: '3rem',
    borderLeft: '1px dashed #CECCCF',
    marginLeft: '1.85rem',
  };

  const txtStyles = {
    fontFamily: 'GT Eesti Text Bold',
    color: '#B8B9C4',
    fontSize: '16px',
    fontWeight: 'normal',
  };

  return (
    <>
      {isLargerThan896 && (
        <Flex
          style={{
            color: '#0B2348',
            fontWeight: 'bold',
            padding: '2rem',
            paddingTop: 0,
            paddingLeft: 0,
            flexDirection: 'column',
            gap: '.3rem',
          }}
        >
          <Flex align={'center'} gap='1rem'>
            <Flex
              style={{
                ...boxStyles,
                padding: '1rem',
              }}
            >
              <FaMapMarkerAlt fontSize={'2rem'} />
            </Flex>

            <Text sx={{ ...txtStyles, color: '#0B2348' }}>{location === '' ? 'Provide your address' : location}</Text>
          </Flex>
          <Box
            style={{
              ...borderStyles,
            }}
          />
          <Flex
            align={'center'}
            gap='1rem'
            filter={step === 1 ? 'blur(5px)' : ''}
          >
            <Flex
              style={{
                ...boxStyles,
              }}
            >
              <FaUserCircle fontSize={'2rem'} />
            </Flex>

            <Box>
              {profileInfo.firstName === '' &&
              profileInfo.lastName === '' &&
              profileInfo.email === '' ? (
                  <Text sx={{ ...txtStyles, color: '#0B2348' }}>Tell us about yourself</Text>
              ) : (
                <>
                  <Text style={{ color: '#0B2348' }}>
                    {profileInfo.firstName + ' ' + profileInfo.lastName}
                  </Text>
                  <Text style={{ ...txtStyles }}>
                    {profileInfo.email} &#8226; {profileInfo.phone}
                  </Text>
                </>
              )}
            </Box>
          </Flex>
          <Box
            style={{
              ...borderStyles,
            }}
          />
          <Flex
            align={'center'}
            gap='1rem'
            filter={step !== 3 ? 'blur(5px)' : ''}
          >
            <Flex
              style={{
                ...boxStyles,
              }}
            >
              <FaHome fontSize={'2rem'} />
            </Flex>

            <Text style={{ ...txtStyles }}>
              Tell us about your vacation rental...
            </Text>
          </Flex>
          <Box
            style={{
              ...borderStyles,
            }}
          />

          <Flex align='center' gap='1rem'>
            <Flex
              style={{
                ...boxStyles,
                filter: 'blur(5px)',
              }}
            >
              <AiFillDollarCircle fontSize={'2rem'} />
            </Flex>
          </Flex>
          <Box style={{ marginTop: '1rem', filter: 'blur(5px)' }}>
            <Image src={BlurredImg} alt='step img' />
          </Box>
        </Flex>
      )}

      {!isLargerThan896 && <DesktopView />}
    </>
  );
};

export default Step;

export const DesktopView = () => {
  // @ts-ignore
  const { step, location } = useContext(stateProvider);

  const boxStyles = {
    background: '#F5F7F9',
    width: '40px',
    height: '40px',
    borderRadius: '50px',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
      <Flex
        style={{
          color: '#0B2348',
          fontWeight: 'bold',
          paddingBlock: '2rem',
          paddingTop: 0,
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '2rem',
        }}
      >
        <Flex align={'center'} gap='1rem'>
          <Flex
            style={{
              ...boxStyles,
              ...boxStyles,
              width: step === 1 ? '60px' : '40px',
              height: step === 1 ? '60px' : '40px',
            }}
          >
            <FaMapMarkerAlt
              fontSize={step === 1 ? '2rem' : '1rem'}
              color={step !== 1 ? 'rgba(1, 27, 53, 0.5)' : ''}
            />
          </Flex>
        </Flex>

        <Flex align={'center'} gap='1rem'>
          <Flex
            style={{
              ...boxStyles,
              width: step === 2 ? '60px' : '40px',
              height: step === 2 ? '60px' : '40px',
            }}
          >
            <FaUserCircle
              fontSize={step === 2 ? '2rem' : '1rem'}
              color={step !== 2 ? 'rgba(1, 27, 53, 0.5)' : ''}
            />
          </Flex>
        </Flex>

        <Flex align={'center'} gap='1rem'>
          <Flex
            style={{
              ...boxStyles,
              width: step === 3 ? '60px' : '40px',
              height: step === 3 ? '60px' : '40px',
            }}
          >
            <FaHome
              fontSize={step === 3 ? '2rem' : '1rem'}
              color={step !== 3 ? 'rgba(1, 27, 53, 0.5)' : ''}
            />
          </Flex>
        </Flex>

        <Flex align='center' gap='1rem'>
          <Flex
            style={{
              ...boxStyles,
              width: step === 4 ? '60px' : '40px',
              height: step === 4 ? '60px' : '40px',
            }}
          >
            <AiFillDollarCircle
              fontSize={step === 4 ? '2rem' : '1rem'}
              color={step !== 4 ? 'rgba(1, 27, 53, 0.5)' : ''}
            />
          </Flex>
        </Flex>
      </Flex>
      {step !== 4 && step !== 3 && (
        <Text color={'rgba(2, 43, 84, 0.5)'} mb='1rem' textAlign={'center'}>
          {location}
        </Text>
      )}
      {step === 3 && (
        <Box style={{ marginTop: '1rem', filter: 'blur(5px)', width: '100%' }}>
          <Image src={BlurredImg} alt='step img' w='100%' />
        </Box>
      )}
    </>
  );
};
