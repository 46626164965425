import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { MapPin } from 'phosphor-react';
import React, { useContext, useRef } from 'react';
import stateProvider from '../../context/stateProvider';
import { useInput } from '../../Hooks/useInput';

const Address = () => {
  // @ts-ignore
  const { location, setPropertyCoordinates, errors, setErrors } =
    useContext(stateProvider);

  const address = useInput();
  const ref = useRef(null);

  const inputStyles = {
    fontFamily: 'GT Eesti Text Medium',
    borderRadius: '24px',
    border: '2px solid #E2E8F0',
    color: `rgba(107, 107, 107, 0.81)`,
    fontSize: '16px',
    background: address.value !== '' && '#e5e5e5',

    _placeholder: {
      color: '#6B6B6B',
    },
    _focus: {},
  };

  return (
    <Flex
      ref={ref}
      direction={'column'}
      position={'relative'}
      marginTop={'1.7rem'}
    >
      <InputGroup>
        <InputLeftElement
          pl={'0.5rem'}
          mt={'.3rem'}
          zIndex={13}
          pointerEvents={'none'}
          children={
            <MapPin
              // color={address.value !== '' ? 'rgb(107, 107, 107)' : '#000000'}
              color='rgb(83, 195, 208)'
              size={24}
            />
          }
        />
        <Input
          autoFocus
          onFocus={(e) => {
            address.setShow(true);
          }}
          placeholder='Enter your address'
          {...address}
          onChange={(e) => {
            if (errors.isLocationError) {
              setErrors({
                ...errors,
                isLocationError: false,
              });
            }
            address.onChange(e, () => {
              /*               setIsSelectedFromDropdown(false);
               */
            });
          }}
          size={'lg'}
          pl='2.7rem'
          zIndex={12}
          sx={{ ...inputStyles }}
          /*           isTyping={address.value !== ''}
           */ _placeholder={{
            color: 'red',
          }}
        />
      </InputGroup>
      {/*       {dropdownLocationError && (
        <Text
          color={'#EE4444'}
          ml={'5px'}
          mt={'5px'}
          fontWeight={'500'}
          fontSize={'15px'}
          fontFamily={'GTMedium'}
        >
          Unable to verify address please select from the dropdown
        </Text>
      )} */}
      {address.show && location !== '' && (
        <Box
          border={'1px solid #e6e6e6'}
          borderRadius='24px'
          zIndex='11'
          pos={'absolute'}
          top='3.5rem'
          w='100%'
          bg={'#FFF'}
          p='.7rem'
          pl={'5px'}
          pt={'1rem'}
        >
          {address.suggestions.map((suggestion: any, index) => {
            return (
              <Flex
                fontFamily={'GT Eesti Text Medium'}
                key={`address-suggestion-${index}`}
                px={'10px'}
                _hover={{
                  bg: '#e5e5e5',
                }}
                gap='.4rem'
                onClick={() => {
                  setPropertyCoordinates(suggestion.geometry.coordinates);
                  address.setShow(false);
                  address.setLocation(suggestion.place_name);
                  /*  setIsSelectedFromDropdown(true);
                   setDropdownLocationError(false); */
                }}
                py='.4rem'
                cursor={'pointer'}
              >
                <Box mt={'2px'}>
                  <MapPin fontSize={'20px'} />
                </Box>
                <Text key={index}>
                  {suggestion.place_name}
                </Text>
              </Flex>
            );
          })}
        </Box>
      )}

      {errors.isLocationError && (
        <Text color={'red'} ml='.5rem'>
          Please fill your address
        </Text>
      )}
    </Flex>
  );
};

export default Address;
