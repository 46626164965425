import { collection, doc, setDoc } from "firebase/firestore"
import { db } from "./firebase"


export const createUser = async (users: any) => {

    const ref = doc(db, 'users', users['User ID']);
    await setDoc(ref, {
        ...users,
        id: ref.id
    })
}