import { Button, Flex, Image, Spacer, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react';

import Logo from './../assets/cohostin.png'

const Header: React.FC = () => {
    const [is768px] = useMediaQuery('(max-width: 768px)')
    const buttonStyle = {
        bg: 'rgb(37, 206, 208)',
        borderRadius: '35px',
        letterSpacing: '0.5px',
        color: 'white',
        fontWeight: 400,
        ml: '6',
        padding: '0px 22px',
        height: '38px',
        transition: 'all 0.2s'

    }

    const handleSchedule = () => {
        // @ts-ignore
        window.SavvyCal('open', { link: 'himel/chat' });

    }
    return (
        <Flex letterSpacing={'0.5px'} p={`0px ${is768px ? '25px' : '46px'}`} height={'85px'} align={'center'} borderBottom={'1px solid rgba(187, 187, 187, 0.8);'}>
            <Flex cursor={'pointer'} onClick={() => window.location.href = 'https://www.cohosting.com'} fontFamily={'Bergen Sans Semi Bold'} alignItems={'center'}>
                <Image w={'27px'} h={'32px'} src={Logo} alt='Cohosting-logo' />
                <Text ml={2} fontSize={'24px'}>Cohosting</Text>
            </Flex>
            <Spacer />

            {
                !is768px ? (<Text fontFamily={'GT Eesti Text Regular'}>+1.801.900.3003</Text>) : ''
            }

            <Button onClick={handleSchedule} _hover={{
                bg: 'rgba(37, 206, 208, 0.8)'
            }} sx={buttonStyle} fontFamily={'GT Eesti Text Light'}>Schedule Call</Button>
        </Flex>
    )
}

export default Header