import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import { MapPin } from 'phosphor-react';
import React, { useContext, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { MdOutlineBathroom, MdOutlineBedroomChild } from 'react-icons/md';
import stateProvider from '../../context/stateProvider';
import Counter from '../Counter';
import CustomInput from '../CustomInput';
import { default as Dropdown } from '../util/Dropdown';
import Amenities, { path } from './Amenities';

const PropertyStats = () => {
  const [isLargerThan896] = useMediaQuery('(min-width: 896px)');
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // @ts-ignore
  const {
    // @ts-ignore
    counts,
    // @ts-ignore
    location,
    // @ts-ignore
    // @ts-ignore
    isClicked,
    // @ts-ignore
    // @ts-ignore
    setIsClicked,
    // @ts-ignore

  } = useContext(stateProvider);

  const { bathrooms, bedrooms, guests } = counts

  const bedroomsRef = useRef(null);
  const bathroomsRef = useRef(null);
  useOutsideClick({
    ref: bedroomsRef,
    handler: () => {
      setIsClicked({
        ...isClicked,
        bedrooms: false,
      });
    },
  });
  useOutsideClick({
    ref: bathroomsRef,
    handler: () => {
      setIsClicked({
        ...isClicked,
        bathrooms: false,
      });
    },
  });


  return (
    <Box>
      <Text
        fontSize={['1.5rem', '1.5rem', '2.5rem']}
        sx={{
          fontFamily: 'GT Eesti Text Bold',
          letterSpacing: '-0.01rem',
          lineHeight: '1.4',
          marginBottom: '2rem',
          textAlign: !isLargerThan896 && 'center',
        }}
      >
        Now let's talk about your vacation rental
      </Text>

      <Box>
        <CustomInput

          icon={<MapPin color='rgb(83, 195, 208)' />}
          value={location}
          // @ts-ignore
          placeholder=''
        />
      </Box>
      <Box>
        <Counter
          color=''
          text={'Bedrooms'}
          value={bedrooms}
          icon
          isDisabled={true}
        />
        <Counter
          color=''
          text={'Bathrooms'}
          value={bathrooms}
          icon
          isDisabled={true}
        />
        <Counter
          color=''
          text={'Guests'}
          value={guests}
          icon
          isDisabled={true}
        />

      </Box>

    </Box>
  );
};

export default PropertyStats;
