import { createContext, useEffect, useState } from 'react';
import qs from 'query-string'
import { v4 as uuidv4 } from 'uuid';

const stateProvider = createContext({
  isClicked: {
    bedrooms: false,
    bathrooms: false,
  },
  selectedValue: { bedrooms: '', bathrooms: '' },
  step: 1,
  selectedProperty: '',
  progress: '',
  counts: { bedrooms: 3, bathrooms: 2, guests: 4 },
  clickHandler: () => {},
  pageForward: () => {},
  pageBack: () => {},
  progressHandler: () => {},
  dropChange: (value) => {},
});

export const StateContext = ({ children }) => {
  const [onClick, setOnClick] = useState(false);
  const [location, setLocation] = useState('');
  const [propertyCoordinates, setPropertyCoordinates] = useState([]);
  const [amenities, setAmenities] = useState([])

  const [isClicked, setIsClicked] = useState({
    bedrooms: false,
    bathrooms: false,
  });

  const [selectedValue, setSelectedValue] = useState({
    bedrooms: '2 Bedrooms',
    bathrooms: '2.5 Bathrooms',
  });

  const [profileInfo, setProfileInfo] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    "User ID": uuidv4()
  });
  const [counts, setCounts] = useState({
    bedrooms: 2,
    bathrooms: 2.5,
    guests: 4,
  });

  const [step, setStep] = useState(1);

  const [errors, setErrors] = useState({
    isLocationError: false,
    inputValidation: {
      fields: [],
      isError: false,
    },
  });

  const pageForward = () => {
    if (step) {
      setStep(step + 1);
    }
    if (step === 4) {
      setStep(step);
    }
    // setOnClick(true);
  };

  const pageBack = () => {
    if (step) {
      setStep(step - 1);
    }
    if (step === 1) {
      setStep(step);
    }

    setErrors({
      ...errors,
      inputValidation: {
        isError: false,
        fields: []
      }
    })
    // setOnClick(!onClick);
  };

  const dropChange = (value) => {
    if (value.toLowerCase().includes('bedrooms')) {
      setIsClicked(!isClicked.bedrooms);
      setSelectedValue({ ...selectedValue, bedrooms: value });
      setCounts({
        ...counts,
        bedrooms: +value.split(' ')[0]
      })

    }

    if (value.toLowerCase().includes('bathrooms')) {
      setIsClicked(!isClicked.bathrooms);
      setSelectedValue({ ...selectedValue, bathrooms: value });
      setCounts({
        ...counts,
        bathrooms: +value.split(' ')[0]
      })
    }
  };


  useEffect(() => {

    if (window.location.search !== '') {
      const { address, coordinates } = qs.parse(window.location.search);
      setLocation(address);
      setPropertyCoordinates(coordinates.split(',').map((el) => +el));
      setStep(2)

    }
  }, [])

  const contextValue = {
    profileInfo,
    setProfileInfo,
    counts,
    step,
    pageForward,
    pageBack,
    setCounts,
    location,
    setLocation,
    propertyCoordinates,
    setPropertyCoordinates,
    errors,
    setErrors,
    isClicked,
    setIsClicked,
    dropChange,
    selectedValue,
    amenities, setAmenities
  };

  return (
    <stateProvider.Provider value={contextValue}>
      {children}
    </stateProvider.Provider>
  );
};

export default stateProvider;
