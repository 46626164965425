import { Box, Button, Divider, Flex, Grid, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FiCalendar, FiMoon } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const AnnualReport: React.FC<{ result: any; isLoading: boolean }> = ({
  result,
  isLoading,
}) => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const [isLargerThan500] = useMediaQuery('(min-width: 500px)');
  const [isLargerThan1100] = useMediaQuery('(min-width: 1100px)');
  const [isLargerThan900h] = useMediaQuery('(min-height: 900px)');
  const navigate = useNavigate()
  const buttonStyles = {
    fontSize: '14px',
    fontWeight: 'bold',
    w: '50%',
    bg: '#25CED0',
    cursor: 'pointer',
    color: '#FFF',
    _focus: {},
    _hover: {},
    _active: {},
  };

  const txtStyles = {
    lineHeight: '1.1',
    letterSpacing: '-2px',
  };

  const boxStyles = {
    alignSelf: 'start',
    p: '1px 5px 1px 10px',
    borderRadius: '14px',
    fontSize: '12px',
    w: '120px',
    color: '#fff',
    mt: '-.7rem',
    ml: '1rem',
    zIndex: '20',
    textAlign: 'center',
    width: 'max-content',
    paddingRight: '10px',
    fontFamily: 'GT Eesti Text Bold'

  };

  let subTextStyle = {
    fontFamily: 'GT Eesti Text Light',
    fontSize: '20px',
    color: '#777777',
    lineHeight: 1.2
  }
  let priceCardWrapper = {
    boxShadow: isLargerThan768 ?  '0px 1px 7px 0px rgba(0, 0, 0, 0.25)' : 'none',
    backgroundColor: '#ffffff',
    overflow: 'visible',
    borderRadius: '27px',
    padding: !isLargerThan500 ? '18px' : '22px 24px',
    mb: !isLargerThan768 && '20px',
    border: !isLargerThan768 ? '1px solid #E6E6E6': 'none'

  }

  let detailsBoxContainer = {
    backgroundColor: '#ffffff',
    overflow: 'visible',
    borderRadius: ' 22px',
    border: '1px solid #BBBBBB',

  }




  let TEXT_COLOR = '#242E43'

  return (
    <Flex
      p={isLargerThan768 ? '5vw' : '0px 20px'}
      py={!isLargerThan900h ? '2vw' : ''}
      direction={'column'}
      align={'center'}
      color='#000'
      textAlign={'center'}
    >
      <Button variant={'ghost'} onClick={() => navigate('/')}>
        <AiOutlineArrowLeft />
        <Text ml={2}>
          Back to search

        </Text>
      </Button>

      {isLoading ? (
        <Box>eer</Box>
      ) : (
          <>

            <>
              <Text sx={{ ...txtStyles, fontFamily: 'GT Eesti Text Bold', color: TEXT_COLOR, mt: !isLargerThan768 && '40px' }} fontSize={['35px', '46px']}>Your Annual Report for... 
              </Text>
              <Box color={TEXT_COLOR} my={!isLargerThan900h ? '17px' : '37px'}>
              <Text
                  fontSize={'24px'}
                letterSpacing={'.7px'}
                  lineHeight='1.1'
                  fontFamily={'GT Eesti Text Medium'}
              >
                {result.location}
              </Text>
                <Text as='span' fontFamily={'GT Eesti Text Light'}>
                  {result.bedrooms} bedrooms · {result.bathrooms} baths
                </Text>

              </Box>
              {
                !isLargerThan768 && (
                  <Divider mb={'18px'} />
                )
              }
              <Text sx={subTextStyle} mb={!isLargerThan900h ? '20px' : '50px'} px={['0px', '70px']}>
                Have any questions about your report? Schedule a call with one of our local specialist to go over your report and answer any questions.
              </Text>
            </>

            <Box w={'100%'} sx={priceCardWrapper}>
              <Text ml={'12px'} mb={'22px'} textAlign={'left'} fontFamily={'GT Eesti Text Bold'} fontSize={'20px'} >Anual Report</Text>
          <Flex
                sx={detailsBoxContainer}
            direction={'column'}
                width='100%'
              >
            <Flex
              sx={{
                ...boxStyles,
                bg: '#25CED0',
              }}
            >
              With Cohostin
            </Flex>
            <DetailBox
              yearly={result?.percentile75.revenue * 12}
              monthly={result?.percentile75.occupancy_rate}
              nightly={result?.percentile75.daily_rate}
            />
          </Flex>

          <Flex
                sx={{ ...detailsBoxContainer, marginTop: '24px' }}
            direction={'column'}
                width='100%'
              >

            <Flex
              sx={{
                ...boxStyles,
                bg: '#DDDDDD',
                color: '#555555',
              }}
            >
              Without Cohostin
            </Flex>
            <DetailBox
                  yearly={result?.average.revenue * 12}
                  monthly={result?.average.occupancy_rate}
                  nightly={result?.average.daily_rate}
            />
          </Flex>
            </Box>



            {isLargerThan1100 && (
              <Button onClick={() => {
                // @ts-ignore
                window.SavvyCal('open', { link: 'himel/chat' });

              }} sx={{ ...buttonStyles, marginTop: '30px', borderRadius: '36px' }}>Schedule call</Button>
          )}
        </>
      )}
    </Flex>
  );
};

export default AnnualReport;

interface DetailsProps {
  yearly: number;
  nightly: number;
  monthly: number;
}

export const DetailBox: React.FC<DetailsProps> = ({
  yearly,
  nightly,
  monthly,
}) => {
  const txtStyles = {
    color: '#000',
    fontSize: '25px',
    lineHeight: '1.2',
    textAlign: 'left',
    fontWeight: 'bold',
  };

  const boxStyles = {
    borderRadius: '12px',
    gap: '1rem',
    color: '#BBBBBB',
    fontSize: '12px',
    fontWeight: 'bold',
    fontFamily: 'GT Eesti Text Bold',
  };

  return (
    <Grid
      sx={{ ...boxStyles, pl: '1rem', pb: '1rem' }}
      templateColumns='repeat(3,1fr)'
    >
      <Text pt={'10px'} sx={{ ...txtStyles, w: 'min-content' }} as='div'>
        {/* @ts-ignore */}
        ${new Intl.NumberFormat('en-US').format(yearly)}
        <Text fontSize={'18px'} color='#BBBBBB'>
          yearly
        </Text>
      </Text>
      <Flex pt={'10px'} direction={'column'} align='center' px='1.5rem'>
        <FiMoon color='#25CED0' size='2.5rem' />
        <Text>${nightly}</Text>
      </Flex>

      <Flex pt={'10px'} direction={'column'} align='center'>
        <FiCalendar color='#25CED0' size='2.5rem' />
        <Text>{monthly}%</Text>
      </Flex>
    </Grid>
  );
};
