import {
  Button,
  Flex,
  Spinner,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import stateProvider from '../../context/stateProvider';
import { generateResult } from '../../lib/result';
import { createUser } from '../../lib/user';
import Schedule from '../modal/Schedule';

const validatePersonalInfo = (info: any, cb: any) => {
  let emptyFiled: any = [];
  Object.keys(info).forEach((el) => {
    if (info[el] === '') {
      emptyFiled.push(el);
    }
  });

  cb(emptyFiled);
  return emptyFiled.length;
};

const StepLayoutButton = () => {
  const navigate = useNavigate();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const [isLargerThan896] = useMediaQuery('(min-width: 896px)');
  const [isGenerating, setIsGenerating] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cookies, setCookie, removeCookie] = useCookies(['hasSubmitForm']);

  // @ts-ignore
  const {
    step,
    pageForward,
    pageBack,
    counts,
    // @ts-ignore
    propertyCoordinates,
    // @ts-ignore
    location,
    // @ts-ignore
    errors,
    // @ts-ignore
    setErrors,
    // @ts-ignore
    profileInfo,
    // @ts-ignore
    amenities,
  } = useContext(stateProvider);
  let nextString = 'Next';
  if (step === 4) {
    nextString = 'view Report';
  }

  const btnStyles = {
    fontFamily: 'GT Eesti Text Medium',
    background: 'rgb(2, 43, 84)',
    color: '#fff',
    width: isLargerThan896 ? '42%' : '100%',
    borderRadius: '24px',
    height: '3em',
    fontWeight: '500',
    fontSize: '16px',

    _hover: {
      background: 'rgb(1, 27, 53)',
    },
    _focus: {},
    _active: {},
  };


  const handleSetCookie = () => {
    const info = JSON.stringify(profileInfo);
    setCookie('hasSubmitForm', info)
  }
  return (
    <Flex
      w={'100%'}
      mt={!isLargerThan768 ? '2rem' : '4rem'}
      // px={!isLargerThan768 ? '1vw' : !isLargerThan896 ? '10vw' : '0vw'}
      direction={isLargerThan896 ? 'row' : 'column-reverse'}
    >
      {step !== 1 && (
        <Button
          onClick={pageBack}
          variant={'ghost'}
          sx={{
            fontFamily: 'GT Eesti Text Light',
            color: 'rgb(28, 93, 159)',
            _hover: {
              color: 'rgb(2, 43, 84)',
            },
            pl: '0',
          }}
        >
          Back to previous step
        </Button>
      )}
      <Button
        onClick={async () => {
          if (step === 1 && location === '') {
            setErrors({
              ...errors,
              isLocationError: true,
            });

            return;
          }

          if (
            step === 2 &&
            validatePersonalInfo(profileInfo, (empty: any) =>
              setErrors({
                ...errors,
                inputValidation: {
                  fields: empty,
                  isError: true,
                },
              })
            )
          ) {
            return;
          }
          if (step === 3) {
            setIsGenerating(true);

            if (cookies.hasSubmitForm) {
              try {


                const response: any = await generateResult(
                  counts,
                  propertyCoordinates,
                  {
                    ...cookies.hasSubmitForm
                  },
                  location,
                  amenities
                );

                if (response.error) {
                  onOpen();
                  setIsGenerating(false);
                  return;
                }
                setIsGenerating(false);
                handleSetCookie();
                // redirect
                navigate(`/results/${response}`);
              } catch (err) {
                console.log(err);
              }
            } else {
              try {

                await createUser(profileInfo);
                // response = {...} or id
                const response: any = await generateResult(
                  counts,
                  propertyCoordinates,
                  profileInfo,
                  location,
                  amenities
                );

                if (response.error) {
                  onOpen();
                  setIsGenerating(false);
                  return;
                }
                setIsGenerating(false);
                handleSetCookie();
                // redirect
                navigate(`/results/${response}`);
              } catch (err) {
                console.log(err);
              }
            }
          } else {
            pageForward();
          }
        }}
        ml={'auto'}
        disabled={isGenerating}
        sx={{
          ...btnStyles,
          // mx: '10px',
        }}
      >
        {' '}
        {isGenerating && <Spinner mr={3} />} {nextString}
        <Schedule isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      </Button>
    </Flex>
  );
};

export default StepLayoutButton;
