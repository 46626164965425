import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { BiUserCircle } from 'react-icons/bi';
import { BsTelephone } from 'react-icons/bs';
import { MdAlternateEmail } from 'react-icons/md';
import stateProvider from '../../context/stateProvider';
import CustomInput from '../CustomInput';

const PersonalInfo = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['hasSubmitForm']);

  const [isLargerThan896] = useMediaQuery('(min-width: 896px)');
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // @ts-ignore
  const { profileInfo, setProfileInfo, errors, setErrors } =
    useContext(stateProvider);
  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }
  const handleChange = (e: any,) => {
    if (cookies.hasSubmitForm) {
      return;
    }
    setErrors({
      ...errors,
      inputValidation: {
        isError: false,
        fields: [],
      },
    });
    const { value, name } = e.target;

    setProfileInfo({
      ...profileInfo,
      [name]: name === 'phone' ? formatPhoneNumber(value) : value,
    });
  };

  useEffect(() => {
    if (!cookies.hasSubmitForm) return;
    setProfileInfo({
      ...cookies.hasSubmitForm
    });
  }, [cookies]);

  return (
    <Flex justify={'center'} direction='column' width={'100%'} h='50%'>
      <Text
        fontSize={['1.5rem', '1.5rem', '2.5rem']}
        sx={{
          fontFamily: 'GT Eesti Text Bold',
          letterSpacing: '-0.01rem',
          lineHeight: '1.4',
          marginBottom: '2rem',
          textAlign: !isLargerThan896 && 'center',
        }}
      >
        Tell us a little about yourself
      </Text>

      <form>
        <Flex
          direction={isLargerThan768 ? 'row' : 'column'}
          gap={!isLargerThan768 ? '0rem' : '.6rem'}
        >
          <CustomInput
            value={profileInfo.firstName}
            handleChange={handleChange}
            icon={<BiUserCircle color='rgb(83, 195, 208)' />}
            // @ts-ignore
            placeholder='First Name'
            name='firstName'
          />
          <Box sx={{
            mt: !isLargerThan768 ? '20px' : '0px',
            w: '100%'
          }}>
          <CustomInput


            value={profileInfo.lastName}
            handleChange={handleChange}
            icon={<BiUserCircle color='rgb(83, 195, 208)' />}
            // @ts-ignore

            placeholder='Last Name'
            name='lastName'
          />

          </Box>
        </Flex>

        <CustomInput
          sx={{
            my: '20px'
          }}
          value={profileInfo.email}
          handleChange={handleChange}
          icon={<MdAlternateEmail color='rgb(83, 195, 208)' />}
          // @ts-ignore

          placeholder='Email'
          name='email'
          type='email'
        />
        <CustomInput
          value={profileInfo.phone}
          handleChange={handleChange}
          icon={<BsTelephone color='rgb(83, 195, 208)' />}
          // @ts-ignore

          placeholder='Phone'
          name='phone'
          type='tel'
        />
      </form>

      {errors.inputValidation.isError && (
        <Text color={'red'} ml='1rem'>
          Please fill the empty fileds
        </Text>
      )}
    </Flex>
  );
};

export default PersonalInfo;
