import { Box, Button, Flex, Grid, GridItem, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const MonthlyData: React.FC<{ result: any }> = ({ result }) => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isLargerThan1100] = useMediaQuery('(min-width: 1100px)');

  const buttonStyles = {
    fontSize: '14px',
    fontWeight: 'bold',
    w: '50%',
    height: '3rem',

    bg: '#25CED0',
    alignSelf: 'center',
    cursor: 'pointer',
    color: '#FFF',
    borderRadius: '8px',
    _focus: {},
    _hover: {},
    _active: {},
  };

  const getKeys = () => {
    console.log({ result })
    const yearKeys = Object.keys(result.monthly_average_granuar_data);
    const currentYear = new Date().getFullYear();

    const data = yearKeys
      .filter((el) => el.includes(currentYear.toString()))
      .sort();
    const lastYearArray = yearKeys
      .filter((year) => !data.some((currentYear) => currentYear === year))
      .sort();
    const mergedArray = [...data, ...lastYearArray];

    if (mergedArray.length > 12) {
      return mergedArray.slice(0, 12);
    }
    return mergedArray;
  };


  return (
    <>
      <Flex
        height={!isLargerThan900 ? 'max-content' : !isLargerThan768 ? '' : '100%'}
        direction={isLargerThan768 ? 'row' : 'column'}
        align={!isLargerThan768 ? '' : 'center'}
        w={'100%'}
        justify={!isLargerThan768 ? 'start' : 'center'}
      >
        {
          isLargerThan768 ? (
            getKeys().map((month, idx) => (
              <Months
                key={Math.random()}
                occupancyRate={
                  result.monthly_average_granuar_data[month].occupancy_rate
                }
                amount={result.monthly_average_granuar_data[month].revenue}
                idx={idx}
              />
            ))

          ) : (
            <Box sx={{
              boxShadow: '0px 20px 40px 0px rgba(198, 217, 225, 0.25)',
              borderRadius: '24px',
              mx: '24px',
                mr: '0px',
              p: '25px',
                border: '1px solid #E6E6E6',
              fontFamily: 'GT Eesti Text Bold'
            }}>
              <Text fontSize={'20px'}>Monthly Breakdown</Text>
              {
                getKeys().map((month, idx) => (
                  <Months
                    key={Math.random()}
                    occupancyRate={
                      result.monthly_average_granuar_data[month].occupancy_rate
                    }
                    amount={result.monthly_average_granuar_data[month].revenue}
                    idx={idx}
                  />
                ))
              }
            </Box>
          )
        }
      </Flex>
      {!isLargerThan1100 && (
        <Box bg={'white'}>

        <Flex justify={'center'} mb='10px' ml='3vw'>
            <Button onClick={() => {
              // @ts-ignore
              window.SavvyCal('open', { link: 'himel/chat' });

            }} sx={{ ...buttonStyles, borderRadius: '36px', marginTop: '15px' }}>Schedule Call</Button>
        </Flex>
        </Box>
      )}

    </>
  );
};

export default MonthlyData;

interface MonthsProps {
  idx: number;
  amount: string;
  occupancyRate: string;
}

export const Months: React.FC<MonthsProps> = ({
  idx,
  amount,
  occupancyRate,
}) => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const [isLargerThan1100] = useMediaQuery('(min-width: 1100px)');

  return (
    <Flex
      direction={'column'}
      height={!isLargerThan768 ? 'max-content' : '80%'}
      alignItems='center'
      align={'center'}
      my={'10px'}
      fontSize={'16px'}
      fontWeight={'bold'}
      gap='2rem'
      _notLast={{
        marginRight: '7px'
      }}
      // fontFamily='GTMedium'
    >
      {isLargerThan768 && (
        <>
          <Box
            bg='rgb(229, 229, 229)'
            width='19px'
            height={!isLargerThan1100 ? '300px' : '80%'}
            borderRadius={'50px'}
            transform={'rotate(180deg)'}
            overflow='hidden'
            order={0}
          >
            <Box w={'100%'} height={occupancyRate + '%'} bg='#25CED0' />
          </Box>
          <Flex
            direction={'column'}
            align='center'
            justify={'center'}
            order={1}
            fontSize='14px'
          >
            {/* @ts-ignore */}
            <Text>{`$${(amount / 1000).toFixed(1)}k`}</Text>
            <Text>{months[idx]}</Text>
          </Flex>
        </>
      )}

      {!isLargerThan768 && (
        <GridItem my={'5px'} w='100%' fontSize={'16px'} fontWeight={'medium'}>
          <Grid templateColumns={'2.7rem 1fr 3.4rem'} alignItems='center' fontFamily="GT Eesti Text Medium"
          >
            <Text mr={'.7rem'}>{months[idx]}</Text>
            <Box
              bg='rgb(229, 229, 229)'
              height='19px'
              borderRadius={'50px'}
              overflow='hidden'
            >
              <Box w={occupancyRate + '%'} height='100%' bg='rgb(37, 206, 208)' />
            </Box>
            {/* @ts-ignore */}
            <Text ml={'auto'} pl='1rem'>{`$${new Intl.NumberFormat('en-US').format(amount)}`}</Text>
          </Grid>
        </GridItem>
      )} 
    </Flex>
  );
};
