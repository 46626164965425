const axios = require("axios");


export const getIncomePrediction =  async (bedrooms: string, bathrooms: string, coordinates: any) => {
  const newBedrooms =  Math.floor(parseInt(bedrooms))
  const newBathrooms =  Math.floor(parseInt(bathrooms))
    const options = {
        method: 'GET',
        url: 'https://airbnb-income-prediction.p.rapidapi.com/getIncomeHistory',
        params: {
          coordinate: `(${coordinates[1]}, ${coordinates[0]})`,
          countryCode: 'US',
          bedrooms: newBedrooms.toString(),
          bathrooms: newBathrooms.toString(),
          hasPool: 'false',
          isCondo: 'true',
          radiusInMeter: '5000'
        },
        headers: {
          'X-RapidAPI-Host': 'airbnb-income-prediction.p.rapidapi.com',
          'X-RapidAPI-Key': '2296d55f65mshf88a20cd74a3c69p17aa1ejsnae76cc48da6b'
        }
      };

      const response = await axios.request(options);
      console.log({response})
      return  response.data;

 }